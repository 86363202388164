import { datadogRum } from "@datadog/browser-rum";
import * as turf from "@turf/turf";
import californiaPolygonGeoJson from './CA_State.json';
import proj4 from 'proj4';

function doSetupRum(){
    console.log("Setting up RUM...");

    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        site: 'us5.datadoghq.com',
        service: process.env.REACT_APP_DD_SERVICE_NAME,
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
}

function isInCalifornia(latitude, longitude) {
    const point = turf.point([longitude, latitude]);
    console.log(`Checking point: ${JSON.stringify(point)}`);

    if (!californiaPolygonGeoJson || !californiaPolygonGeoJson.features) {
        console.error('Invalid GeoJSON structure', californiaPolygonGeoJson);
        return false;
    }

    for (const feature of californiaPolygonGeoJson.features) {
        if (turf.booleanPointInPolygon(point, feature)) {
            console.log("Point is in California.");
            return true;
        }
    }

    console.log("Point is NOT in California.");
    return false;
}

function translateCoordinateSystemBrowserToGeoJson(latitude, longitude){
    const proj4326 = '+proj=longlat +datum=WGS84 +no_defs'; // EPSG:4326
    const proj3857 = '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'; // EPSG:3857

    const webMercatorCoords = proj4(proj4326, proj3857, [longitude, latitude]);
    const translatedLogitude = webMercatorCoords[0];
    const translatedLatitude = webMercatorCoords[1];

    return [translatedLogitude, translatedLatitude]
}

export default async function handleRumSetup() {
    try {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;

                const translated = translateCoordinateSystemBrowserToGeoJson(latitude, longitude);
                const translatedLongitude = translated[0];
                const translatedLatitude = translated[1];

                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                console.log(`Translated Latitude: ${translatedLatitude}, Longitude: ${translatedLongitude}`);

                let isCA = isInCalifornia(translatedLatitude, translatedLongitude);
                console.log(`Is CA? ${isCA}`);
                let ok = isCA !== null;

                if (ok && !isCA) {
                    doSetupRum();
                } else {
                    console.log("User is in CA. Skipping RUM.");
                }
            },
            (error) => {
                console.error('Error getting user location:', error);
            }
        );
    } catch (error) {
        console.error("Error while setting up RUM.");
        console.error(error.message);
    }
}

