import React, {Component} from 'react'
import {NavLink, withRouter} from "react-router-dom";
import {
    Form,
    Grid,
    Icon,
    Loader,
    Message,
    Table,
    Transition,
    Dimmer,
} from "semantic-ui-react";
import {connect} from "react-redux";
import PortalModalHeader from "../portalModalHeader";
import {buildFullName, dowloadBase64, formatMoney, resolveTierShort, strFormatMoney} from "../../../utils/utils";
import moment from "moment";
import axios from "axios";
import PortalFooter from "../portalFooter";
import PaymentAuthorizationCard from "./PaymentAuthorizationCard";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {StrategicPartnerLogo} from '../../common';
import {Header} from '../../base';
import STRATEGIC_PARTNER from '../../../constants/strategicPartners';
import {ClickableText, CustomContainer, CustomDivider} from '../../custom-common';

const invoiceNotFoundMsg = 'Invoice not found.'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        contract: state.portal.contract,
        historicalInvoices: state.billing.historicalInvoices,
        isSelfBill: state.billing.isSelfBill
    }
};

class InvoiceDetails extends Component {
    pageSize = 10
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            loadingGetDocument: false,
            loadingFirstPage: false,
            lastPageLoaded: false,
            tableLoading: false,
            tableLoadingOnSearch: false,
            error: null,
            column: null,
            direction: "ascending",
            adjDirection: "ascending",
            adjColumn: null,
            filteredData: [],
            selectedRowIndex: null,
            search: '',
            invoice: null,
            employeeSummaries: null,
            adjSearch: '',
            adjFilteredData: [],
            showPaymentSuccess: false,
            billingGroupFilteredData: [],
            billingGroupSearch: '',
            filteredEmployeesList: [],
            subtotal: '',
            maxDisplayCount: 10,
            loadingViewMore: false,
            invoiceNotFound: false
        }
    }

    async componentDidMount() {
        await this.callRefreshInvoice()
    }

    onSearchTextChange = (event, {value}) => {
        this.setState({search: value}, () => this.filter())
    }

    filter = () => {
        const {
            employeeSummaries,
            billingGroupFilteredData,
            lastPageLoaded,
            search,
        } = this.state
        const filteredData = employeeSummaries.filter(item => {
            if (search === '') {
                this.setState({tableLoadingOnSearch: false})
                return true
            }
            if(!lastPageLoaded){
                this.setState({tableLoadingOnSearch: true})
            }
            const searchValue = search.toLowerCase()
            return `${item.firstName} ${item.lastName}`.toLowerCase().includes(searchValue)
        })

        const [filteredEmployeesList, subtotal] = this.updateFilteredEmployeesList(filteredData, billingGroupFilteredData);
        this.setState({filteredData, filteredEmployeesList, subtotal})
    }

    filterBillingGroup = (event, {value}) => {
        const {employeeSummaries, filteredData} = this.state;
        const billingGroupFilteredData = employeeSummaries.filter(item => {
            if (value === '') {
                return true
            }
            return item.billingGroup === value
        })
        const [filteredEmployeesList, subtotal] = this.updateFilteredEmployeesList(filteredData, billingGroupFilteredData);
        this.setState({billingGroupFilteredData, billingGroupSearch: value, filteredEmployeesList, subtotal})
    }

    updateFilteredEmployeesList = (filteredData, billingGroupFilteredData) => {
        const filteredEmployeesList = filteredData.filter(value => billingGroupFilteredData.includes(value));
        const subtotal = this.getSubtotal(filteredEmployeesList);
        return [filteredEmployeesList, subtotal];
    }

    getSubtotal = (employeesList) => {
        let sumX100 = employeesList.reduce((accum, employee) => {
            let amountX100 = employee.totalAmount.replace('.', '');
            amountX100 = parseInt(amountX100);
            return accum + amountX100;
        }, 0)
        sumX100 = '' + sumX100;
        return [sumX100.slice(0, sumX100.length - 2), ".", sumX100.slice(sumX100.length - 2)].join('');
    }

    filterAdjustments = (event, {value}) => {
        const {invoice} = this.state;
        const filteredData = invoice.adjustments.filter(item => {
            if (value === '') {
                return true
            }
            const searchValue = value.toLowerCase()
            return item.type.toLowerCase().includes(searchValue)
        })
        this.setState({adjFilteredData: filteredData, adjSearch: value})
    }

    handleSort = (column) => {
        const {direction, filteredData, lastPageLoaded} = this.state
        if(!lastPageLoaded) {
            this.setState({tableLoading: true})
        }
        const newDirection =  direction === "ascending" ? "descending" : "ascending"
        this.setState({column, direction: newDirection})
        this.sort(filteredData, column, newDirection)
    }

    sort = (data, column, direction) => {
        if (!data) {
            return
        }
        const sortableItems = data
        sortableItems.sort((a, b) => {
            let v1, v2
            v1 = a[column]
            v2 = b[column]
            if (v1 < v2) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (v1 > v2) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        this.setState({
            filteredData: sortableItems,
            filteredEmployeesList: sortableItems,
            selectedRowIndex: null
        });
    }

    sortAdj = (column) => {
        const {adjDirection, adjFilteredData} = this.state;
        const sortableItems = adjFilteredData;
        sortableItems.sort((a, b) => {
            let v1, v2
            v1 = a[column]
            v2 = b[column]
            if (v1 < v2) {
                return adjDirection === 'ascending' ? -1 : 1;
            }
            if (v1 > v2) {
                return adjDirection === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        this.setState({
            adjDirection: adjDirection === "ascending" ? "descending" : "ascending",
            adjColumn: column,
            adjFilteredData: sortableItems,
            selectedRowIndex: null
        });
    }

    toggleDetail = (index) => {
        const {selectedRowIndex} = this.state
        if (index === selectedRowIndex) {
            index = null
        }
        this.setState({selectedRowIndex: index})
    }

    renderInvoiceRow = (employeeSummary, index) => {
        const {selectedRowIndex} = this.state;
        const showDetails = selectedRowIndex === index;
        const {id, firstName, lastName, totalAmount, employeeTransactions} = employeeSummary;
        const showRowFlag = employeeTransactions.some(item => !item.completePeriod || !item.currentPeriod);
        const invoiceId = this.props.match.params.id;

        return (<React.Fragment key={index}>
            <Table.Row>
                <Table.Cell><NavLink exact to={{
                    pathname: '/portal/employee/' + id,
                    state: {
                        name: buildFullName(firstName, null, lastName, null),
                        customPrevRoute: '/portal/invoice/' + invoiceId
                    }
                }}>{firstName}</NavLink></Table.Cell>
                <Table.Cell><NavLink exact to={{
                    pathname: '/portal/employee/' + id,
                    state: {name: buildFullName(firstName, null, lastName, null)}
                }}>{lastName}</NavLink></Table.Cell>
                <Table.Cell collapsing>
                    {showRowFlag ? <CustomContainer className={"bkgLinen"} basic
                                            style={{
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderRadious: '5px'
                                            }}>{strFormatMoney(totalAmount)} <Icon name={"flag"}
                                                                                   className={"peach"}/></CustomContainer> :
                        <CustomContainer basic style={{
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }}>{strFormatMoney(totalAmount)}</CustomContainer>
                    }
                </Table.Cell>
                <Table.Cell textAlign={"right"} collapsing onClick={() => {
                    this.toggleDetail(index)
                }}><ClickableText>{showDetails ? "Collapse" : "Expand"}</ClickableText>
                    <Icon name={showDetails ? 'caret up' : 'caret down'} link/>
                </Table.Cell>
            </Table.Row>
            {showDetails && <Table.Row>
                <Table.Cell colSpan={4} style={{borderTop: 'none'}}>
                    <Transition.Group animation={"slide down"} duration={100}>
                        <CustomContainer basic>
                            <Header as={"h5"}><b>Premium details</b></Header>

                            {employeeTransactions.map((item) => {
                                return <CustomContainer basic
                                                className={!item.completePeriod || !item.currentPeriod ? "bkgLinen" : ""}>
                                    <Grid columns={4}>
                                        <Grid.Column><span
                                            className={"secondaryText"}><b>{item.currentPeriod === true ? "This month" : "Previous coverage"}</b></span></Grid.Column>
                                        <Grid.Column>{item.startDate? moment(item.startDate).format('MMM Do, YYYY') : "-"}</Grid.Column>
                                        <Grid.Column>{item.endDate? moment(item.endDate).format('MMM Do, YYYY') : "-"}</Grid.Column>
                                        <Grid.Column>{strFormatMoney(item.amount)} {(!item.completePeriod || !item.currentPeriod) &&
                                        <Icon name={"flag"} className={"peach"}/>}  </Grid.Column>
                                    </Grid>
                                </CustomContainer>
                            })}


                            <CustomDivider hidden/>
                            <Header as={"h5"}><b>Coverage details</b></Header>
                            {employeeTransactions.map((item) => {
                                return <CustomContainer basic>
                                    <Grid columns={6}>
                                        <Grid.Column width={12} style={{padding: 0}}>
                                            <span
                                                className={"smaller"}>Effective Date <b>{moment(item.startDate).format('MMM Do, YYYY')} - {moment(item.endDate).format('MMM Do, YYYY')}</b></span>
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"}>
                                            <div className={"neutral700Text smaller"}><b>Moderate</b></div>
                                            <span className={"secondaryText"}>
                                            <b>{formatMoney(item.benefits?.moderate)}</b>
                                        </span>
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"}>
                                            <div className={"neutral700Text smaller"}><b>Severe</b></div>
                                            <span className={"secondaryText"}>
                                            <b>{formatMoney(item.benefits?.severe)}</b>
                                        </span>
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"}>
                                            <div className={"neutral700Text smaller"}><b>Catastrophic</b></div>
                                            <span className={"secondaryText"}>
                                            <b>{formatMoney(item.benefits?.catastrophic)}</b>
                                        </span>
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"}>
                                            {!!item.benefits?.chronic &&
                                            <React.Fragment>
                                                <div className={"neutral700Text smaller"}><b>Chronic</b></div>
                                                <span className={"secondaryText"}>
                                            <b>{formatMoney(item.benefits?.chronic)}</b>
                                            </span>
                                            </React.Fragment>
                                            }
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"} style={{maxWidth: '137px'}}>
                                            {!!item.benefits?.mental &&
                                            <React.Fragment>
                                                <div className={"neutral700Text smaller"}><b>Mental</b></div>
                                                <span className={"secondaryText"}>
                                                <b>{formatMoney(item.benefits?.mental)}</b>
                                                </span>
                                            </React.Fragment>
                                            }
                                        </Grid.Column>

                                        <Grid.Column className={"bkgNeutral100"} style={{marginLeft: '10px'}}>
                                            <div className={"neutral700Text smaller"}><b>Tier</b></div>
                                            <span className={"secondaryText"}>
                                            <b>{resolveTierShort(item.familyTier)}</b>
                                        </span>
                                        </Grid.Column>
                                    </Grid>
                                </CustomContainer>
                            })}
                        </CustomContainer>
                    </Transition.Group>
                </Table.Cell>
            </Table.Row>}
        </React.Fragment>)
    }

    renderAdjustmentRow = (adjustment, index) => {
        const {amount, type, comment} = adjustment;
        if(!type){
            return null
        }
        return (<React.Fragment key={index}>
            <Table.Row>
                <Table.Cell className="secondaryText"><b>{type}</b></Table.Cell>
                <Table.Cell>{comment}</Table.Cell>
                <Table.Cell>{formatMoney(amount)}</Table.Cell>
            </Table.Row>
        </React.Fragment>)
    }

    downloadDoc = async (docId) => {
        this.setState({loading: true, error: null})
        try {
            const result = await axios.post("/api/employer/v2/getDocument", {"documentId": docId}, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            dowloadBase64(data.document.name, data.document.contentType, data.document.body)
            this.setState({loading: false})
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get the document"})
        }
    }

    callRefreshInvoice = async (showPaymentSuccess) => {
        const invoiceId = this.props.match.params.id;
        this.setState({loadingFirstPage: true, error: null})
        try {
            const payload = {invoiceId}
            const result = await axios.post("/api/employer/v2/invoiceDetails", payload, {headers: {'Content-Type': 'application/json'}});
            const nextQueryMore = result.data.queryMore
            const lastPageLoaded = nextQueryMore? false: true
            this.setState({
                lastPageLoaded,
                loadingFirstPage: false,
                invoice: result.data.invoice,
                employeeSummaries: result.data.employeeSummaries,
                filteredData: result.data.employeeSummaries,
                billingGroupFilteredData: result.data.employeeSummaries,
                adjFilteredData: result.data.invoice.adjustments,
                showPaymentSuccess: showPaymentSuccess,
                filteredEmployeesList: result.data.employeeSummaries,
                subtotal: this.getSubtotal(result.data.employeeSummaries),
            }, ()=> this.filter())
            if (!lastPageLoaded) {
                await this.reCallInvoice(showPaymentSuccess, nextQueryMore)
            }
        } catch (e) {
            console.warn(e)
            if (e.response.data?.errorMessage === invoiceNotFoundMsg) {
                this.setState({ invoiceNotFound: true })
            }
            this.setState({loadingFirstPage: false, error: "Unable to get invoice info"})
        }
    }

    async reCallInvoice(showPaymentSuccess, queryMore) {
        const {
            maxDisplayCount,
            filteredEmployeesList,
            column,
            direction,
        } = this.state
        clearTimeout(this.timer);
        const invoiceId = this.props.match.params.id;
        const payload = {invoiceId, queryMore}

        try {
            const result = await axios.post("/api/employer/v2/invoiceDetails", payload, {headers: {'Content-Type': 'application/json'}});
            const nextQueryMore = result.data.queryMore
            const lastPageLoaded = nextQueryMore? false: true
            this.setState({
                invoice: {
                    ...result.data.invoice,
                    adjustments: [
                        ...this.state.invoice.adjustments,
                        ...result.data.invoice.adjustments
                    ],
                    documents: [
                        ...this.state.invoice.documents,
                        ...result.data.invoice.documents
                    ],
                    billingGroups: [
                        ...this.state.invoice.billingGroups,
                        ...result.data.invoice.billingGroups
                    ]
                },
                employeeSummaries: [
                    ...this.state.employeeSummaries,
                    ...result.data.employeeSummaries
                ],
                filteredData: [
                    ...this.state.filteredData,
                    ...result.data.employeeSummaries
                ],
                billingGroupFilteredData: [
                    ...this.state.billingGroupFilteredData,
                    ...result.data.employeeSummaries
                ],
                adjFilteredData: [
                    ...this.state.adjFilteredData,
                    ...result.data.invoice.adjustments,
                ],
                showPaymentSuccess: showPaymentSuccess,
                filteredEmployeesList: [
                    ...this.state.filteredEmployeesList,
                    ...result.data.employeeSummaries
                ],
                subtotal: this.getSubtotal([
                    ...this.state.employeeSummaries,
                    ...result.data.employeeSummaries,
                ]),
            }, () => {
                this.sort([...filteredEmployeesList, ...result.data.employeeSummaries], column, direction)
                this.filter()
            })
            if(lastPageLoaded) {
                if(maxDisplayCount > (filteredEmployeesList.length + result.data.employeeSummaries.length)){
                    this.setState({maxDisplayCount: filteredEmployeesList.length + result.data.employeeSummaries.length})
                }
                return this.setState({
                    lastPageLoaded,
                    tableLoading: false,
                    tableLoadingOnSearch: false,
                })
            }

            this.timer = setTimeout(
              () => this.reCallInvoice(showPaymentSuccess, nextQueryMore).then(),
              100
            );
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get invoice info"})
        }
    }

    switchToPaymentDetail = () => {
        this.props.history.push({pathname: "/portal/billing", state: {activeTab: 'paymentDetails'}});
    }

    contractPolicyNumber = () => {
        const {contract} = this.props
        if (!contract?.contract) return ''
        const {carrierSpecificPolicyId, policyNumber} = contract.contract
        return carrierSpecificPolicyId || policyNumber
    }

    onViewMore = () => {
        const {
            maxDisplayCount,
            filteredEmployeesList,
        } = this.state
        if (filteredEmployeesList.length > maxDisplayCount) {
            this.setState({maxDisplayCount: maxDisplayCount + this.pageSize})
        }
   }

   showViewMoreLoading = () => {
       const {
           maxDisplayCount,
           filteredEmployeesList,
           lastPageLoaded,
       } = this.state
       if(lastPageLoaded || !this.showViewMore()) {
           return false
       }
       return maxDisplayCount > filteredEmployeesList.length
   }

    showViewMore = () => {
        const {
            maxDisplayCount,
            filteredEmployeesList,
            lastPageLoaded,
        } = this.state
        return !(this.isTableLoading() || (lastPageLoaded && maxDisplayCount >= filteredEmployeesList.length))
    }

    isTableLoading = () => {
        const {tableLoading, tableLoadingOnSearch} = this.state
        return tableLoading || tableLoadingOnSearch
    }

    showLoader = () => {
        const {loadingFirstPage, loadingGetDocument} = this.state
        return loadingFirstPage || loadingGetDocument
    }

    render() {
        const {prevRoute, contract, isSelfBill} = this.props;
        const {
            showPaymentSuccess,
            error,
            column,
            direction,
            search,
            invoice,
            adjSearch,
            adjFilteredData,
            adjColumn,
            adjDirection,
            filteredEmployeesList,
            subtotal,
            maxDisplayCount,
            invoiceNotFound
        } = this.state;
        const billType = this.props.location?.state?.type;
        const title = billType === "preview" ? "Bill Preview" : "Bill";
        const trigger = <><Icon name={"download"} color={"grey"}/><a>Download</a></> // eslint-disable-line jsx-a11y/anchor-is-valid

        return (
            <React.Fragment>
                <PortalModalHeader modalTitle={title} prevRoute={prevRoute} done={true}/>
                <div className="employer-portal-container" style={{background: 'white'}}>
                    {invoiceNotFound && <Message error> {invoiceNotFoundMsg}</Message>}
                    {this.showLoader()? <Loader active/> :
                        !!invoice ? <Grid container stackable columns={1} centered>
                            <Grid.Column className={"pageContent"}>
                                {(invoice.paymentStatus !== "Closed" && !isSelfBill) &&
                                <>
                                    <CustomDivider hidden/>
                                        <PaymentAuthorizationCard twoColumnsLayout
                                                                  accountBalance={invoice.amountDue}
                                                                  invoiceId={invoice.id}
                                                                  afterRefresh={this.callRefreshInvoice}
                                                                  showPaymentSuccess={showPaymentSuccess}
                                                                  switchToPaymentDetail={this.switchToPaymentDetail}
                                        />
                                </>
                                }
                                <CustomDivider hidden/>
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <StrategicPartnerLogo />
                                    </Grid.Column>
                                    <Grid.Column textAlign={"right"}>
                                        <div className={"small"}>
                                            <div>ReliaStar Life Insurance Company</div>
                                            <div>3702 Paysphere Circle</div>
                                            <div>Chicago, IL 60674</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <div className={"secondaryText"}><b>{contract.employer.legalEntityName}</b>
                                        </div>
                                        <div className={"small"}>
                                            <div>{contract.employer.address.street}</div>
                                            <div>{contract.employer.address.city}, {contract.employer.address.state}, {contract.employer.address.zipCode}</div>
                                            <div className={"neutral700Text"}><b>Policy #{this.contractPolicyNumber()}</b></div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Row verticalAlign={"middle"}>
                                        <Grid.Column>
                                            <Header as={"h1"}>{strFormatMoney(invoice.totalAmount)}<span
                                                style={{fontSize: '20px'}}> total due</span></Header>
                                        </Grid.Column>
                                        <Grid.Column textAlign={"right"}>
                                            <Dropdown trigger={trigger} icon={null} direction={"left"}>
                                                <Dropdown.Menu direction={"left"}
                                                               style={{minWidth: '200px'}}>
                                                    <Dropdown.Item text={"Summary Details"} onClick={() => {
                                                        this.downloadDoc(invoice.invoiceSummary)
                                                    }}/>
                                                    {
                                                        !!invoice.invoiceFullDetails &&
                                                        <Dropdown.Item text={"Full Invoice"} onClick={() => {
                                                            this.downloadDoc(invoice.invoiceFullDetails)
                                                        }}/>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <CustomDivider/>

                                <Grid columns={4}>
                                    <Grid.Column>
                                        <div className={"neutral700Text small"}><b>Due date</b></div>
                                        <div className={"secondaryText"}>
                                            <b>{moment(invoice.dateDue).format("MMM D, YYYY")}</b></div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className={"neutral700Text small"}><b>Coverage period</b></div>
                                        <div className={"secondaryText"}>
                                            <b>{moment(invoice.billingPeriod.startDate).format("MMM D, YYYY")}-{moment(invoice.billingPeriod.endDate).format("MMM D, YYYY")}</b>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className={"neutral700Text small"}><b>Invoice number</b></div>
                                        <div className={"secondaryText"}><b>{invoice.invoiceNumber}</b></div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className={"neutral700Text small"}><b>Date generated</b></div>
                                        <div className={"secondaryText"}>
                                            <b>{moment(invoice.preparedDate).format("MMM D, YYYY")}</b></div>
                                    </Grid.Column>

                                    {(!!invoice.billingGroups && invoice.billingGroups.length > 0) &&
                                    <>
                                        <Grid.Column>
                                        <CustomDivider hidden/>

                                        <Form>
                                            <Form.Field>
                                                <label>View by billing group</label>
                                                    <Dropdown
                                                        clearable
                                                        options={invoice.billingGroups}
                                                        selection
                                                        onChange={this.filterBillingGroup}
                                                        style={{width: '221px'}}
                                                    />
                                            </Form.Field>
                                        </Form>
                                        <CustomDivider hidden/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <CustomDivider hidden section/>
                                            <span className={"neutral700Text small"}><b>Subtotal: {strFormatMoney(subtotal)}</b></span>
                                        </Grid.Column>
                                    </>
                                    }
                                </Grid>

                                <Grid columns={2}>
                                    <Grid.Row verticalAlign={"middle"}>
                                        <Grid.Column><Header as={"h3"}>Employee details</Header></Grid.Column>
                                        <Grid.Column textAlign={"right"}>
                                            <span className={"small"}>Details look incorrect? Reach out to us at {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form style={{marginTop: 0}}>
                                            <Form.Input icon='search' iconPosition='left'
                                                        placeholder={"Filter by employee name..."} value={search}
                                                        name={search}
                                                        onChange={this.onSearchTextChange}/>
                                        </Form>
                                        {error && <Message error> {error}</Message>}
                                    </Grid.Column>
                                </Grid>

                                <CustomDivider hidden/>
                                <Dimmer.Dimmable dimmed>
                                    <Dimmer inverted active={this.isTableLoading()}/>
                                    <Loader active={this.isTableLoading()} />
                                    <Table sortable basic={"very"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{minWidth: "135px"}}
                                                              sorted={column === 'firstName' ? direction : null}
                                                              onClick={() => this.handleSort('firstName')}
                                            >
                                                First name {column !== 'firstName' && <Icon name="sort"/>}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={column === 'lastName' ? direction : null}
                                                onClick={() => this.handleSort('lastName')}
                                            >
                                                Last name {column !== 'lastName' && <Icon name="sort"/>}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={column === 'totalAmount' ? direction : null}
                                                onClick={() => this.handleSort('totalAmount')}
                                            >
                                                Amount {column !== 'totalAmount' && <Icon name="sort"/>}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {filteredEmployeesList && filteredEmployeesList.slice(0, maxDisplayCount).map((item, index) => (
                                            this.renderInvoiceRow(item, index)
                                        ))}
                                    </Table.Body>
                                </Table>
                                <Grid stackable columns={1}>
                                    <Grid.Row columns={1} textAlign={'center'} verticalAlign={'middle'}>
                                        <Grid.Column textAlign="center">
                                            {this.showViewMoreLoading() ? (
                                                <Loader active style={{marginTop: 15}} />
                                            ) : this.showViewMore() && (
                                                <ClickableText
                                                   onClick={this.onViewMore}>
                                                    View More
                                                </ClickableText>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                </Dimmer.Dimmable>


                                {invoice.adjustments.length > 0 && <React.Fragment>
                                    <Header as={"h3"}>Non employee related adjustments</Header>

                                    <Form style={{marginTop: 0}}>
                                        <Form.Input icon='search' iconPosition='left'
                                                    placeholder={"Filter by adjustment type..."} value={adjSearch}
                                                    name={adjSearch}
                                                    onChange={this.filterAdjustments}/>
                                    </Form>
                                    {error && <Message error> {error}</Message>}

                                    <CustomDivider hidden/>
                                    <Table sortable basic={"very"}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{minWidth: "135px"}}
                                                                  sorted={adjColumn === 'type' ? adjDirection : null}
                                                                  onClick={() => this.sortAdj('type')}
                                                >
                                                    Type {adjColumn !== 'type' && <Icon name="sort"/>}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Memo
                                                </Table.HeaderCell>
                                                <Table.HeaderCell
                                                    sorted={adjColumn === 'amount' ? adjDirection : null}
                                                    onClick={() => this.sortAdj('amount')}
                                                >
                                                    Amount {adjColumn !== 'amount' && <Icon name="sort"/>}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {adjFilteredData.map((item, index) => (
                                                this.renderAdjustmentRow(item, index)
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </React.Fragment>}
                            </Grid.Column>
                        </Grid> : null
                    }
                </div>
                <PortalFooter/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(InvoiceDetails));
